import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import arrowUpDown from "../images/arrowUpDown.png";
export default function DynamicTableProduct({
  columns,
  data,
  setNbr,
  nbr,
  total,
  last_page,
  name,
}) {

  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  return (
    <div className="dynamic-table-container">
      <div className="pt-2 pb-3">
        <div className="filtre-product pb-3">
        </div>
      </div>
      <span className="d-flex align-items-center pb-3">
      </span>
      <table {...getTableProps()} className="dynamic-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img
                        src={arrowUpDown}
                        alt="Down Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    ) : (
                      <img
                        src={arrowUpDown}
                        alt="Up Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor: rowIndex % 2 === 0 ? "white" : "#F2F5F8",
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="container mb-5">
        <div className="row">
          <div className="col-7">
            <p className="h6">Total de {total} lignes </p>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            {nbr > 1 && (
              <strong
                onClick={() => {
                  setNbr(nbr - 1);
                }}
              >
                Précédent
              </strong>
            )}

            {/* Affichage des numéros de page */}
            {Array.from({ length: last_page }, (_, index) => index + 1).map(
              (pageNum) => {
                // Afficher les trois premières pages
                if (pageNum <= 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher les trois dernières pages
                else if (pageNum > last_page - 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher la page actuelle et les pages voisines
                else if (pageNum >= nbr - 1 && pageNum <= nbr + 1) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher des points pour les autres pages
                else if (pageNum === 4 || pageNum === last_page - 3) {
                  return <strong key={pageNum}>...</strong>;
                }
              }
            )}

            {/* Bouton "Suivant" */}
            {nbr < last_page && (
              <strong
                onClick={() => {
                  setNbr(nbr + 1);
                }}
              >
                Suivant
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
