import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ClickBack from "../../../components/buttons/ClickBack";
import { useHistory, useParams } from "react-router-dom";
import {
  FetchAllLivreur,
  FetchListFournisseur,
  GetOneCommandeById,
  updateFournisseurCommande,
} from "./DataService";
import { ErrorMessage, Field, Formik, useFormikContext } from "formik";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import * as Yup from "yup";
import ModalValidation from "../../../components/modal/ModalValidation";
import AutoCalculateTTC from "../Component/AutoCalculateTTC";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../../utils/ApiConfig.js";

function EditCommandeFournisseur() {
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [file, setFile] = useState(null);
  //Fonction pour la validation du formulaire d'ajout
  const SignupSchema = Yup.object().shape({
    fournisseur: Yup.string().required("Fournisseur est requis"),
    NBonLivraison: Yup.string().required("Numéro Bon de Livraison est requis"),
    livraisonHT: Yup.string().required("Tarif de la livraison est requis"),
    commission: Yup.string().required("Commission est requise"),
    TVA: Yup.string().required("TVA est requis"),
    TTC: Yup.string().required("TTC est requis"),
    livreur: Yup.string().required("Livreur est requis"),
    telephone_client: Yup.string()
      .required("N° de Telephone Client est requis")
      .matches(
        /^0\d{9}$/,
        "Le numéro doit commencer par 0 et contenir 10 chiffres"
      ),
    NomClient: Yup.string().required("Nom Client est requis"),
    PrenomClient: Yup.string().required("prènom Client est requis"),
    RaisonSocialClient: Yup.string().required(
      "Raison Social Client est requis"
    ),
    // TelFournisseur: Yup.string().required(
    //   "N° de Telephone Fournisseur est requis"
    // ),
    telFournisseurVal: Yup.string().required(
      "N° de Telephone Fournisseur est requis"
    ),
    DateCommande: Yup.string().required("Date Commande est requis"),
    DateLivraison: Yup.string().required("Date Livraisson est requis"),
    StatuPaiement: Yup.string().required("Statut Paiement est requis"),
    StatuCommande: Yup.string().required("Statut Commande est requis"),
    MontantCommande: Yup.number().required("Montant de la Commande est requis"),
    pickupAdr: Yup.string().required("Adresse Pick up est requis"),
    address: Yup.string().required("Adresse de Livraison est requis"),
    selectedFournisseurEmail: Yup.string()
      .email("Email invalide")
      .required("Email est requis"),
    file:
      !file || typeof file == "string"
        ? undefined
        : Yup.mixed().test(
            "fileSize",
            "La taille du fichier ne doit pas dépasser 25 Mo",
            (value) => {
              return value && value.size <= 25 * 1024 * 1024; // 25 Mo en octets
            }
          ),
  });

  const [showOkModal, setShowOkModal] = useState(false);
  const [selectedFournisseurEmail, setSelectedFournisseurEmail] = useState("");
  const [tarifLiv, setTarifLiv] = useState("");
  const [comissionVal, setComissionVal] = useState("");
  const [listFournisseurs, setListFournisseur] = useState([]);
  const [listLivreur, setListLivreur] = useState([]);
  const [deliveryLatLng, setDeliveryLatLng] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState(null);
  const [telFournisseurVal, setTelFournisseurVal] = useState("");
  const [address, setAddress] = useState("");
  const [pickupAdr, setPickUpAdr] = useState("");
  const history = useHistory();
  const { idCommande } = useParams();
  const currentDate = new Date().toISOString().slice(0, 16);

  const handleChangeFournisseur = (e, setFieldValue) => {
    const selectedFournisseurId = e.target.value;
    const selectedFournisseur = listFournisseurs.find(
      (fournisseur) => fournisseur.id == selectedFournisseurId
    );
    if (selectedFournisseur) {
      setSelectedFournisseurEmail(selectedFournisseur.email);
      setFieldValue("selectedFournisseurEmail", selectedFournisseur?.email);
      setTarifLiv(selectedFournisseur?.user_profil?.montantLivraison);
      setFieldValue(
        "livraisonHT",
        selectedFournisseur?.user_profil?.montantLivraison
      );
      setComissionVal(selectedFournisseur?.user_profil?.commission);
      setFieldValue("commission", selectedFournisseur?.user_profil?.commission);
      setTelFournisseurVal(
        selectedFournisseur?.user_profil?.TelFournisseur || ""
      );
      setFieldValue(
        "telFournisseurVal",
        selectedFournisseur?.user_profil?.TelFournisseur
      );
    }
  };

  const handelUpload = (elemtFile, setFieldValue) => {
    setFieldValue("file", elemtFile);
  };
  const HandleSave = async (values) => {
    const formData = new FormData();
    formData.append("id_fournisseur", values.fournisseur);
    selectedFournisseurEmail &&
      formData.append("emailFournisseur", selectedFournisseurEmail);
    telFournisseurVal && formData.append("TelFournisseur", telFournisseurVal);
    formData.append("NomClient", values.NomClient);
    formData.append("PrenomClient", values.PrenomClient);
    pickupAdr && formData.append("AdressePickUp", pickupAdr);
    pickupLatLng && formData.append("altitudeFournisseur", pickupLatLng.lat);
    pickupLatLng && formData.append("longitudeFournisseur", pickupLatLng.lng);
    address && formData.append("AdresseLivraison", address);
    deliveryLatLng && formData.append("altitudeLivreur", deliveryLatLng.lat);
    deliveryLatLng && formData.append("longitudeLivreur", deliveryLatLng.lng);
    formData.append("id_livreur", values.livreur);
    formData.append("Commentaire", values.Commentaire);
    formData.append("DateCommande", currentDate);
    formData.append("DateLivraison", values.DateLivraison);
    formData.append("TelClient", values.telephone_client);
    formData.append("StatuPaiement", values.StatuPaiement);
    formData.append("StatuCommande", values.StatuCommande);
    formData.append("NBonLivraison", values.NBonLivraison);
    file && formData.append("Bordereau", file);
    formData.append("MontantCommande", values.MontantCommande);
    formData.append("livraisonHT", values.livraisonHT);
    formData.append("commission", values.commission);
    formData.append("TVA", values.TVA);
    formData.append("TTC", values.TTC);
    formData.append("RaisonSocialClient", values.RaisonSocialClient);
    try {
      const response = await updateFournisseurCommande(idCommande, formData);
      setShowOkModal(true);
      setTimeout(() => {
        history.push("/commandes_Allfournisseur");
      }, 1001); // 1001 millisecondes (2 secondes)
    } catch (error) {
      if (error.response && error.response.status === 422) {
      } else {
        console.error(
          "There was an error adding the fournisseur order!",
          error
        );
      }
    }
  };

  const handleChangeAddress = (address, type, setFieldValue) => {
    if (type === "pickup") {
      setFieldValue("pickupAdr", address);
      setPickUpAdr(address);
    } else {
      setFieldValue("address", address);
      setAddress(address);
    }
  };

  const handleSelect = async (address, type, setFieldValue) => {
    try {
      const results = await geocodeByAddress(address);
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        if (type === "pickup") {
          setFieldValue("pickupAdr", address);
          setFieldValue("longitudeFournisseur", lng);
          setFieldValue("altitudeFournisseur", lat);
          setPickUpAdr(address);
          setPickupLatLng({ lat, lng });
        } else {
          setFieldValue("address", address);
          setAddress(address);
          setFieldValue("altitudeLivreur", lat);
          setFieldValue("longitudeLivreur", lng);
          setDeliveryLatLng({ lat, lng });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const searchOptions = {
    componentRestrictions: { country: ["fr","mc"] },
  types: ["geocode","establishment"],
  };

  //Cette fonction pour afficher liste des fournisseurs
  const fetchFournisseurData = async () => {
    try {
      const response = await FetchListFournisseur();
      setListFournisseur(response?.data);
    } catch (error) {
      console.error("Error lors de affiche liste fournisseur:", error);
    }
  };
  //Cette fonction pour afficher liste des livreurs
  const fetchLivreurData = async () => {
    try {
      const response = await FetchAllLivreur();
      setListLivreur(response.data);
    } catch (error) {
      console.error("Error lors de affiche liste livreur:", error);
    }
  };

  //Cette fonction pour fermer le Pop Up
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };

  const fetchOrderData = async () => {
    try {
      const order = await GetOneCommandeById(idCommande);
      setOrderDetails(order);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  useEffect(() => {
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true);
    }
  }, []);
  useEffect(() => {
    if (orderDetails) {
      setPickUpAdr(orderDetails?.AdressePickUp || "");
      setAddress(orderDetails?.AdresseLivraison || "");
      setPickupLatLng({
        lat: orderDetails?.altitudeFournisseur || 0,
        lng: orderDetails?.longitudeFournisseur || 0,
      });
      setDeliveryLatLng({
        lat: orderDetails?.altitudeLivreur || 0,
        lng: orderDetails?.longitudeLivreur || 0,
      });
      setFile(orderDetails?.Bordereau);
      setSelectedFournisseurEmail(orderDetails?.emailFournisseur);
      setTelFournisseurVal(orderDetails?.TelFournisseur);
    }
  }, [orderDetails]);
  useEffect(() => {
    fetchOrderData(idCommande);
    fetchFournisseurData();
    fetchLivreurData();
  }, [idCommande]);
  // ,pickupLatLng,deliveryLatLng,address,pickupAdr
  return (
    <Container>
      <div>
        <p className="h6">Accueil / Commandes Founisseurs / Modification </p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Modification commande fournisseur</h1>
          </Col>
        </Row>

        {orderDetails && (
          <Formik
            initialValues={{
              fournisseur: orderDetails?.id_fournisseur || "",
              // TelFournisseur: orderDetails?.TelFournisseur || "",
              telFournisseurVal: orderDetails?.TelFournisseur || "",
              NomClient: orderDetails?.NomClient || "",
              PrenomClient: orderDetails?.PrenomClient || "",
              pickupAdr: orderDetails?.AdressePickUp || "",
              altitudeFournisseur: orderDetails?.altitudeFournisseur || "",
              longitudeFournisseur: orderDetails?.longitudeFournisseur || "",
              address: orderDetails?.AdresseLivraison || "",
              altitudeLivreur: orderDetails?.altitudeLivreur || "",
              longitudeLivreur: orderDetails?.longitudeLivreur || "",
              livreur: orderDetails?.id_livreur || "",
              DateCommande: orderDetails?.DateCommande
                ? orderDetails?.DateCommande.substring(0, 16)
                : "",
              DateLivraison: orderDetails?.DateLivraison
                ? orderDetails?.DateLivraison.substring(0, 16)
                : "",
              telephone_client: orderDetails?.TelClient || "",
              RaisonSocialClient: orderDetails?.RaisonSocialClient || "",
              selectedFournisseurEmail: orderDetails?.emailFournisseur || "",
              StatuPaiement: orderDetails?.StatuPaiement || "",
              StatuCommande: orderDetails?.StatuCommande || "",
              files: orderDetails?.Bordereau,
              NBonLivraison: orderDetails?.NBonLivraison,
              MontantCommande: orderDetails?.MontantCommande || "",
              Commentaire: orderDetails?.Commentaire || "",
              livraisonHT: orderDetails?.livraisonHT || "",
              commission: orderDetails?.commission || "",
              TVA: 20,
              TTC: orderDetails?.TTC || "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              HandleSave(values);
              // setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validateField,
            }) => (
              <Form onSubmit={handleSubmit}>
                <AutoCalculateTTC />
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title className="pb-3">
                      Détails de la commande
                    </Card.Title>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="listFournisseur"
                        >
                          <Form.Label>Fournisseur</Form.Label>
                          <Form.Select
                            className="form-control"
                            value={values.fournisseur}
                            name="fournisseur"
                            onChange={(e) => {
                              handleChange(e);
                              handleChangeFournisseur(e, setFieldValue);
                            }}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.fournisseur && !!errors.fournisseur
                            }
                          >
                            <option value="" disabled hidden>
                              Sélectionnez un Fournisseur
                            </option>
                            {listFournisseurs.length > 0 ? (
                              listFournisseurs.map((fournisseur) => (
                                <option
                                  key={fournisseur.id}
                                  value={fournisseur.id}
                                >
                                  {fournisseur.user_profil.RaisonSocial}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                Aucun fournisseur disponible
                              </option>
                            )}
                          </Form.Select>
                          <ErrorMessage
                            name="id_fournisseur"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formEmail_fournisseur"
                        >
                          <Form.Label>Adresse mail fournisseur</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="email"
                            placeholder="nom@exemple.com"
                            name="selectedFournisseurEmail"
                            value={
                              values?.email_fournisseur ||
                              selectedFournisseurEmail
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              setSelectedFournisseurEmail(value);
                              setFieldValue("selectedFournisseurEmail", value);
                            }}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.selectedFournisseurEmail &&
                              !!errors.selectedFournisseurEmail
                            }
                          />
                          <ErrorMessage
                            name="selectedFournisseurEmail"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formtelFournisseurVal"
                        >
                          <Form.Label>N° de téléphone Fournisseur </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="telFournisseurVal"
                            placeholder="N° de téléphone"
                            value={values?.TelFournisseur || telFournisseurVal}
                            onChange={(e) => {
                              const value = e.target.value;
                              setTelFournisseurVal(value);
                              setFieldValue("telFournisseurVal", value);
                            }}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.telFournisseurVal &&
                              !!errors.telFournisseurVal
                            }
                          />
                          <ErrorMessage
                            name="telFournisseurVal"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formNom_Nomclient"
                        >
                          <Form.Label>Nom Client </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="NomClient"
                            placeholder="Nom Client"
                            value={values.NomClient}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.NomClient && !!errors.NomClient}
                          />
                          <ErrorMessage
                            name="NomClient"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formPrenom_client"
                        >
                          <Form.Label>Prénom Client </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="PrenomClient"
                            placeholder="Prénom Client"
                            value={values.PrenomClient}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.PrenomClient && !!errors.PrenomClient
                            }
                          />
                          <ErrorMessage
                            name="PrenomClient"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formTelephone_client"
                        >
                          <Form.Label>N° de téléphone client </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="telephone_client"
                            placeholder="Télephone Client"
                            value={values.telephone_client}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.telephone_client &&
                              !!errors.telephone_client
                            }
                          />
                          <ErrorMessage
                            name="telephone_client"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formRaisonSocial_client"
                        >
                          <Form.Label>Raison Social Client </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="RaisonSocialClient"
                            placeholder="Raison Social Client"
                            value={values.RaisonSocialClient}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.RaisonSocialClient &&
                              !!errors.RaisonSocialClient
                            }
                          />
                          <ErrorMessage
                            name="RaisonSocialClient"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="listLivreur">
                          <Form.Label>Livreur</Form.Label>
                          <Form.Select
                            className="form-control"
                            value={values.livreur}
                            name="livreur"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.livreur && !!errors.livreur}
                          >
                            <option value="" disabled hidden>
                              Sélectionnez un livreur
                            </option>
                            {listLivreur?.map((livreur) => (
                              <option key={livreur.id} value={livreur.id}>
                                {livreur.nom} {livreur.prenom}
                              </option>
                            ))}
                          </Form.Select>
                          <ErrorMessage
                            name="livreur"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Label>Date de la commande : </Form.Label>
                        <input
                          type="datetime-local" // Définition du type datetime-local
                          id="DateCommande"
                          name="DateCommande"
                          className={`form-control ${
                            touched.DateCommande && errors.DateCommande
                              ? "danger-input"
                              : ""
                          }`}
                          value={values.DateCommande}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.DateCommande && !!errors.DateCommande
                          }
                        />
                        <ErrorMessage
                          name="DateCommande"
                          component="div"
                          className="text-danger"
                        />
                      </Col> */}
                      <Col>
                        <Form.Label>Heure de livraison souhaitée: </Form.Label>
                        <input
                          type="datetime-local" // Définition du type datetime-local
                          id="DateLivraison"
                          name="DateLivraison"
                          className={`form-control ${
                            touched.DateLivraison && errors.DateLivraison
                              ? "danger-input"
                              : ""
                          }`}
                          value={values.DateLivraison}
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.DateLivraison && !!errors.DateLivraison
                          }
                        />
                        <ErrorMessage
                          name="DateLivraison"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBordereau">
                          <Form.Label>
                            Bordereau{" "}
                            {values?.files && (
                              <b> : Un fichier Attaché {values?.files}</b>
                            )}
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            type="file"
                            name="file"
                            placeholder="Bordereau"
                            accept=".pdf"
                            //value={values.Bordereau}
                            onChange={(event) => {
                              const elemtFile = event.currentTarget.files[0];
                              setFile(elemtFile);
                              handelUpload(elemtFile, setFieldValue);
                            }}
                            onBlur={handleBlur}
                            isInvalid={touched.file && !!errors.file}
                          />
                          <ErrorMessage
                            name="file"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formLastName">
                          <Form.Label>Statut de Commande</Form.Label>
                          <Form.Select
                            className="form-control"
                            type="text"
                            name="StatuCommande"
                            placeholder="statut commande"
                            value={values.StatuCommande}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.StatuCommande && !!errors.StatuCommande
                            }
                          >
                            <option>Sélectionnez le Statut de Commande</option>
                            <option value="1">En cours de livraison</option>
                            <option value="2">Livré</option>
                            <option value="3">En cours de préparation</option>
                            <option value="6">Annulé</option>
                          </Form.Select>
                          <ErrorMessage
                            name="statut_commande"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formStatutPaiement"
                        >
                          <Form.Label>Statut de paiement</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-control"
                            id="StatuPaiement"
                            name="StatuPaiement"
                            placeholder="Statut paiement"
                            value={values.StatuPaiement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.StatuPaiement && !!errors.StatuPaiement
                            }
                          >
                            <option>Sélectionnez le Statut de paiement</option>
                            <option value="1">Facturé</option>
                            <option value="2">Non Facturé</option>
                          </Form.Select>
                          <ErrorMessage
                            name="StatuPaiement"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formMontantCommande"
                        >
                          <Form.Label>Montant de la commande</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="number"
                            name="MontantCommande"
                            placeholder="Montant de la commande"
                            // value={values.MontantCommande}
                            value={values?.MontantCommande}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.MontantCommande &&
                              !!errors.MontantCommande
                            }
                            disabled="true"
                          />
                          <ErrorMessage
                            name="MontantCommande"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    {isGMapsLoaded && (
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formAddresspickup"
                          >
                            <Form.Label>Adresse Pick up</Form.Label>
                            <Autocomplete
                              name="pickupAdr"
                              value={pickupAdr}
                              // value={orderDetails?.AdressePickUp}
                              searchOptions={searchOptions}
                              onChange={(addr) =>
                                handleChangeAddress(
                                  addr,
                                  "pickup",
                                  setFieldValue
                                )
                              }
                              onSelect={(addr) =>
                                handleSelect(addr, "pickup", setFieldValue)
                              }
                              onBlur={handleBlur}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="position-relative">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Adresse Pick Up",
                                      className: `form-control mb-3 ${
                                        Error !== "" ? "danger-input" : ""
                                      }`,
                                    })}
                                    id="floatingPickupAdr"
                                  />
                                  <div
                                    className={`container-autocomplete ${
                                      suggestions.length > 0
                                        ? "d-block"
                                        : "d-none"
                                    }`}
                                  >
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41B6E6"
                                          : "#fff",
                                      };
                                      return (
                                        <div
                                          className="mapItem"
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Autocomplete>
                            <ErrorMessage
                              name="pickupAdr"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Adresse Livraison</Form.Label>
                            <Autocomplete
                              name="address"
                              value={address}
                              // value={orderDetails?.AdresseLivraison}
                              searchOptions={searchOptions}
                              onChange={(addr) =>
                                handleChangeAddress(addr, "liv", setFieldValue)
                              }
                              onSelect={(addr) =>
                                handleSelect(addr, "liv", setFieldValue)
                              }
                              onBlur={handleBlur}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="position-relative">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Adresse Livraison",
                                      className: `form-control mb-3 ${
                                        Error !== "" ? "danger-input" : ""
                                      }`,
                                    })}
                                    id="floatingAdr"
                                  />
                                  <div
                                    className={`container-autocomplete ${
                                      suggestions.length > 0
                                        ? "d-block"
                                        : "d-none"
                                    }`}
                                  >
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41B6E6"
                                          : "#fff",
                                      };
                                      return (
                                        <div
                                          className="mapItem"
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Autocomplete>
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBondelivraison"
                        >
                          <Form.Label>Numéro Bon de livraison</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="NBonLivraison"
                            placeholder="Numéro Bon de livraison"
                            value={values.NBonLivraison}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.NBonLivraison && !!errors.NBonLivraison
                            }
                          />
                          <ErrorMessage
                            name="NBonLivraison"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formCommentaire"
                        >
                          <Form.Label>Commentaire</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="Commentaire"
                            placeholder="Commentaire"
                            value={values.Commentaire}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.Commentaire && !!errors.Commentaire
                            }
                          />
                          <ErrorMessage
                            name="Commentaire"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* Montants */}
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title className="pb-3">Montants :</Card.Title>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formTarifLivraison"
                        >
                          <Form.Label>Tarif de Livraison</Form.Label>
                          <Field
                            type="number"
                            name="livraisonHT"
                            placeholder="Tarif de Livraison"
                            className="form-control"
                            value={values?.livraisonHT || tarifLiv}
                            onChange={(e) => {
                              const value = e.target.value;
                              setTarifLiv(value);
                              setFieldValue("livraisonHT", value);
                            }}
                          />
                          <ErrorMessage
                            name="livraisonHT"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formCommission">
                          <Form.Label>Commission %</Form.Label>
                          <Field
                            type="number"
                            name="commission"
                            placeholder="Commission %"
                            className="form-control"
                            value={values?.commission || comissionVal}
                            onChange={(e) => {
                              const value = e.target.value;
                              setComissionVal(value);
                              setFieldValue("commission", value);
                            }}
                          />
                          <ErrorMessage
                            name="commission"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formMontantCommande"
                        >
                          <Form.Label>Montant de la commande</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="number"
                            name="MontantCommande"
                            placeholder="Montant de la commande"
                            value={values.MontantCommande}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.MontantCommande &&
                              !!errors.MontantCommande
                            }
                          />
                          <ErrorMessage
                            name="MontantCommande"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formtva">
                          <Form.Label>TVA</Form.Label>
                          <Field
                            type="number"
                            name="TVA"
                            placeholder="TVA"
                            className="form-control"
                            disabled="true"
                          />
                          <ErrorMessage
                            name="TVA"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3" controlId="formMontantTTC">
                          <Form.Label>Montant TTC</Form.Label>
                          <Field
                            type="number"
                            name="TTC"
                            placeholder="Montant TTC"
                            className="form-control"
                            value={values?.TTC}
                            disabled="true"
                          />
                          <ErrorMessage
                            name="TTC"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      className="mt-3 d-flex justify-content-end align-items-center"
                      controlId="formSubmit"
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Enregistrer la commande
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <ModalValidation
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
        text={"Votre commande a été enregistrée et envoyer avec succès"}
      />
    </Container>
  );
}

export default EditCommandeFournisseur;
