import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({ searchTerm, handleSearch }) => {
  return (
    <div className="search-bar d-flex align-items-center form-control">
      <input
        type="text"
        className=" form-control search-input-border"
        placeholder="Rechercher"
        value={searchTerm}
        onChange={(event) => handleSearch(event.target.value)} // Update search term
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSearch(searchTerm); // Trigger search on Enter key
          }
        }}
      />
      <button
        className="search-with-filtre"
        type="submit"
        onClick={() => handleSearch(searchTerm)}
      >
        <FontAwesomeIcon icon={faSearch} size="1x" />
      </button>
    </div>
  );
};

export default SearchBar;
