// dataService.js
import api from '../../utils/ApiConfig';

const fetchProductFiltred = async (nbr, search_term, marque, parent_categorie, categorie, min_price, max_price, min_stock, max_stock) => {
  try {
    // Créez un objet avec tous les paramètres
    const params = {
      search_term,
      marque,
      parent_categorie,
      categorie,
      min_price,
      max_price,
      min_stock,
      max_stock,
      page: nbr,
    };

    // Filtrez les paramètres pour ne conserver que ceux qui sont définis et non vides
    const filteredParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    const response = await api.get(`/products/filter-search?${filteredParams}`);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};



// Fetch one product form database ************************************************************
const fetchOneProductData = async (id) => {
  try {
    // Fetch all products based on the product id
    const allProductsResponse = await api.get(`/products/${id}/details`);
    // Ensure that the response contains the necessary data
    if (allProductsResponse.data && allProductsResponse.data) {
      // Extract the array of products from the response
      return allProductsResponse.data.product;
    } else {
      // If the product with the given id is not found
      throw new Error('Product not found');
    }

  } catch (error) {
    console.error('Error fetching product data:', error);
    throw error;
  }
};
// Update product status  ************************************************************
const updateProductStatus = async (productId) => {
  try {
    const response = await api.post(`/products/${productId}/update-status/0`);

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated product data
      return response;
    } else {
      throw new Error(`Failed to update product status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating product status:', error);
    throw error;
  }
};

// Save product data  ************************************************************
const saveProductData = async (productData) => {
  try {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    const response = await api.post(`/products/fullProductAdd`, productData,config);
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated product data
      return response;
    } else {
      throw new Error(`Failed to update product data. Statuss: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating product datax:', error);
    throw error;
  }
};
// Update product data  ************************************************************
const updateProductData = async (productData) => {
  try {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    const response = await api.post(`/products/update`, productData,config);
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated product data
      return response;
    } else {
      throw new Error(`Failed to update product data. Statuss: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating product datax:', error);
    throw error;
  }
};
// Fetch all categories form database ************************************************************
const fetchCategoryData = async () => {
  try {
    const response = await api.get(`/categoriesBO`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
// Fetch all brand form database ************************************************************
const fetchBrandData = async () => {
  try {
    const response = await api.get(`/marques`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
// Update product data  ************************************************************  
const updateOptiontData = async (id, optionData) => {
  try {
    const response = await api.post(`/products/options/${id}/update`, { "option": optionData });
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated product data
      return response;
    } else {
      throw new Error(`Failed to update product data. Statuss: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating product datax:', error);
    throw error;
  }
};

//Delete File in Product   api/products/files/{filesId}/delete
const DeleteFileInProduct = async (idFile) => {
  try {
    const response = await api.delete(`/products/files/${idFile}/delete`);
    if (response.status === 200) {
      // Check if the response data is valid and return the updated product data
      return response;
    } else {
      throw new Error(`Failed to dlete product files . Statuss: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

//change statut product
const changeStatus = async (productId) => {
  try {
    const response = await api.get(`/products/delete/${productId}`);
    if (response.status === 200) {
      // Check if the response data is valid and return the delete product is done
      return response;
    } else {
      throw new Error(`Failed to change status produc. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in change status product :', error);
    throw error;
  }
};

//Fontion to get subCategries  get(‘
const fechCategoriesPerParents = async (parent) => {
  try {
    // Fetch all subCategories based on the categorie parent id
    const response = await api.get(`/categories/${parent}`);
    // Ensure that the response contains the necessary data
    if (response.status === 200) {
      // Check if the response data is valid
      return response;
    } else {
      throw new Error(`Failed to get subCategorie. Status: ${response.status}`);
    }

  } catch (error) {
    console.error('Error fetching subCategorie:', error);
    throw error;
  }
};
// Fetch categorie parent  form database ************************************************************
const fetchCategorieParent = async (subCategorieId) => {
  try {
    const response = await api.get(`/categories/child/${subCategorieId}`);
    return response?.data;
  } catch (error) {
    console.error('Error fetching categorie Parent data:', error);
    throw error;
  }
};


// Export methods ************************************************************
export {fetchOneProductData, updateProductStatus, saveProductData, updateProductData, fetchCategoryData, fetchBrandData, updateOptiontData, changeStatus,  DeleteFileInProduct, fechCategoriesPerParents, fetchCategorieParent,fetchProductFiltred };