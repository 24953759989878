// Import necessary dependencies and components
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Badge,
  Spinner,
} from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import {
  fetchOneProductData,
  updateProductStatus,
  updateProductData,
  updateOptiontData,
  fetchCategoryData,
  DeleteFileInProduct,
  fechCategoriesPerParents,
  fetchCategorieParent,
} from "./DataService";
import DynamicTable from "../../components/DynamicTable";
import { fetchCategoriesData } from "../Categories/DataService";
import { fetchBrandsDatano } from "../Brands/DataService";
import IconTrash from "../../images/trash.png";
import ConfirmationModal from "../../components/modal/ConfirmationModal"; // Bootstrap modal
import OkModal from "../../components/modal/OkModal"; // Bootstrap modal
import moment from "moment";
import _ from "lodash";
import ImageUploder from "./ImageUploder";
import { urlPdf } from "../../utils/ApiConfig"; 

// Define the functional component
function EditScreen() {
  // Modal state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOkModal, setShowOkModal] = useState(false); // New state for OkModal
  // Action type state
  const [actionType, setActionType] = useState("");
  // Use history
  const history = useHistory();
  // State to hold product data
  const [productData, setProductData] = useState(null);
  // Use useParams to get productId from the URL
  const { productId } = useParams();
  // State for loading indicator
  const [loading, setLoading] = useState(false);
  const [categorieParente, setCategorieParente] = useState("");

  // State to store options for marque and categorie
  const [marquesOptions, setMarquesOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorieSelected, setCategorieSelected] = useState("");
  const [optionsProducts, SetOptionsProducts] = useState();
  const [fils, setFils] = useState([]);
  const [errors, setErrors] = useState({});
  const [subCategorie, setSubCategorie]= useState([]);


  // Initialize state variables for form fields
  const initialState = {
    id: null,
    titre: null,
    slug: null,
    reference: null,
    description: null,
    specification: null,
    conseil: null,
    ht: null,
    ttc: null,
    status: null,
    tva: null,
    quantite: null,
    initial_quantity: null,
    created_by: null,
    marque: null,
    categorie: null,
    status: null,
    created_at: null,
    updated_at: null,
    product_category: {
      id: null,
      name: null,
      slug: null,
      image: null,
      metier: null,
      parent_id: null,
      created_at: null,
      updated_at: null,
    },
    product_marque: {
      id: null,
      name: null,
      slug: null,
      image: null,
      created_at: null,
      updated_at: null,
      description: null,
    },
    product_option: [
      {
        id: null,
        product: null,
        option: null,
        value: null,
        created_at: null,
        updated_at: null,
      },
      // Repeat similar structure for other options
    ],
    images: [
      {
        id: null,
        product_id: null,
        type: null,
        path: null,
        featured: null,
        created_at: null,
        updated_at: null,
      },
      // Repeat similar structure for other images
    ],
  };
  // State for product data and errors
  const [product, setProduct] = useState({
    // Initial product data
    titre: "",
    slug: "",
    description: "",
    reference: "",
    specification: "",
    conseil: "",
    ht: 0,
    ttc: 0,
    tva: 0,
    quantite: 0,
    marque: "",
    categorie: "",
    status: "",
    options: [],
    images: [],
  });
  // Function to handle input changes for options
  const handleOptionChange = (index, field, value) => {
    const newOptions = [...optionsProducts];
    newOptions[index] = { ...newOptions[index], [field]: value };
    SetOptionsProducts(newOptions);
  };

  const supprimerLigne = (index) => {
    const nouvellesOptions = [...optionsProducts];
    nouvellesOptions.splice(index, 1); // Supprime l'élément à l'index spécifié
    SetOptionsProducts(nouvellesOptions);
  };

  // Function to add a new option
  const handleAddOption = () => {
    SetOptionsProducts([...optionsProducts, { key: "", value: "" }]);
  };

  const [state, setState] = useState(initialState);

  //******************************************************************************************************************/
  // Function to handle form field changes
  // const handleChange = (key, value) => {
  //   setState((prevState) => {
  //     const newState = _.cloneDeep(prevState);
  //     _.set(newState, key, value);
  //     return newState;
  //   });
  // };
  //Fonction pour supprimer les photos d'un produit
  const handleDeleteImage = async (fileId) => {
    try {
      const resp = await DeleteFileInProduct(fileId); // Supposons que fetchMarquesOptions() retourne un tableau d'objets représentant les options de marque
      // Met à jour l'état avec les options de marque récupérées
      window.location.reload();
    } catch (error) {
      console.error("Error lors de la suppression du fichier :", error);
    }
  };

  // Open delete modal and set action type
  const handleDeleteClick = () => {
    // Set the action type to 'delete'
    setActionType("delete");
    // Show the confirmation modal before deleting
    setShowConfirmationModal(true);
  };
  // Open save modal and set action type
  const handleSaveClick = () => {
    // Set the action type to 'delete'
    setActionType("save");
    // Show the confirmation modal before deleting
    setShowConfirmationModal(true);
  };
  const handelUpdateOptions = () => {
    // Set the action type to 'delete'
    setActionType("saveOption");
    // Show the confirmation modal before deleting
    setShowConfirmationModal(true);
  };
  // Function to handle OkModal close
  const handleOkModalClose = () => {
    setShowOkModal(false);
    // Optionally, you can perform additional actions after OkModal is closed
  };
  //******************************************************************************************************************/

  // Effectue la requête pour récupérer les options de catégorie
  const fetchCategories = async () => {
    try {
      // Met à jour l'état avec les options de catégorie récupérées
      const Catdata = await fetchCategoryData();
      setCategories(Catdata); 
      setSubCategorie(Catdata) 

    } catch (error) {
      console.error("Error fetching categories options:", error);
    }
  };

  const renderSelectOptions = (categories, level = 0) => {
    if (!Array.isArray(categories) || categories.length === 0) {
      return null;
    } 
    return categories.map((category) => (
      <React.Fragment key={category.id}>
        <option value={category.id}>
          {"--".repeat(level)} {category.name}
        </option>
        {category.subcategories &&
         category.subcategories.length > 0 &&
          renderSelectOptions(category.subcategories, level + 1)}
      </React.Fragment>
    ));
  };
  
    const handleChoice = async (value) => {
      setCategorieParente(value);
      try {
        const response = await fechCategoriesPerParents(value); // Correct typo: `fechCategoriesPerParents` -> `fetchCategoriesPerParents`
        if (response?.data) {
          setSubCategorie(response.data); // Update subcategories for the selected parent
        } else {
          setSubCategorie([]); // Clear if no subcategories exist
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        // Optional: Set a user-visible error state
        setErrors((prev) => ({ ...prev, subCategorie: "Error fetching subcategories" }));
      }
    };
    
  // Effectue la requête pour récupérer les options de marque
  const fetchMarques = async () => {
    try {
      const marques = await fetchBrandsDatano(); // Supposons que fetchMarquesOptions() retourne un tableau d'objets représentant les options de marque
      setMarquesOptions(marques); // Met à jour l'état avec les options de marque récupérées
    } catch (error) {
      console.error("Error fetching marques options:", error);
    }
  };
  // Function to fetch product data using DataService
  const fetchData = async (id) => {
    try {
      setLoading(true);
      const product = await fetchOneProductData(id);
      // Check if the response is an object
      if (product && typeof product === "object" && !Array.isArray(product)) {
        // Use fetchOneProductData to fetch data for a specific product
        setProductData(product); 
        setCategorieSelected(product?.categorie)
        getCatParent(product?.categorie);
        const optionKeys = [];

        // Utiliser la méthode map pour extraire les valeurs de la clé "option"
        product.product_option.map((product) => {
          optionKeys.push({
            id: product.id,
            key: product.option,
            value: product.value,
          });
        });
    
        SetOptionsProducts(optionKeys);
        const updatedData = {
          id: product.id || "",
          titre: product.titre || "",
          slug: product.slug || "",
          reference: product.reference || "",
          description: product.description || "",
          specification: product.specification,
          conseil: product.conseil || "",
          ht: product.ht || "",
          ttc: product.ttc || "",
          tva: product.tva || "",
          quantite: product.quantite || "",
          initial_quantity: product.initial_quantity || "",
          created_by: product.created_by || "",
          marque: product.marque || "",    
          categorie: product.categorie || "",
          // categorieParente:categorieParente ,    
          status: product.status || "1",
          created_at: product.created_at || "",
          updated_at: product.updated_at || "",
          product_category: {
            id: product.product_category?.id || "",
            name: product.product_category?.name || "",
            slug: product.product_category?.slug || "",
            image: product.product_category?.image || "",
            metier: product.product_category?.metier || "",
            parent_id: product.product_category?.parent_id || "",
            created_at: product.product_category?.created_at || "",
            updated_at: product.product_category?.updated_at || "",
          },
          product_marque: {
            id: product.product_marque?.id || "",
            name: product.product_marque?.name || "",
            slug: product.product_marque?.slug || "",
            image: product.product_marque?.image || "",
            created_at: product.product_marque?.created_at || "",
            updated_at: product.product_marque?.updated_at || "",
            description: product.product_marque?.description || "",
          },
          product_option: product.product_option.map((option) => ({
            id: option.id || "",
            product: option.product || "",
            option: option.option || "",
            value: option.value || "",
            created_at: option.created_at || "",
            updated_at: option.updated_at || "",
          })),
          images: product.images.map((image) => ({
            id: image.id || "",
            product_id: image.product_id || "",
            type: image.type || "",
            path: image.path || "",
            featured: image.featured || "",
            created_at: image.created_at || "",
            updated_at: image.updated_at || "",
          })),
        };
        // Update state using the updatedData
        setState(updatedData);
      } else {
        // Handle the case where the response is not as expected
        console.error(
          "Invalid response format - Products data is not an object:",
          product
        );
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  //******************************************************************************************************************/
  // Function to handle DELETE confirmation
  const handleDeleteConfirm = async () => {
    try {
      // Specify the product ID and the new status value (0 in this example)
      await updateProductStatus(productId);
      history.go(0);
    } catch (error) {
      console.error("Error updating product status:", error);
    }
  };
  //******************************************************************************************************************/
  // Object with the updated product data
  const updatedProductData = {
      id: state.id || "",
      titre: state.titre || "",
      slug: state.slug || "",
      reference: state.reference || "",
      description: state.description || "",
      specification: state.specification || "",
      conseil: state.conseil || "",
      ht: state.ht || "",
      ttc: state.ttc || "",
      status: state.status || "",
      tva: state.tva || "",
      quantite: state.quantite || "",
      initial_quantity: state.initial_quantity || "",
      created_by: state.created_by || "",
      marque: state.marque  || "",
      // categorieParente:state.categorieParente ,     
      categorie: state.categorie  || "",
      status: state.status + "" || "1",
      created_at: state.created_at || "",
      updated_at: state.updated_at || "",
      product_category: {
        id: state.product_category?.id || "",
        name: state.product_category?.name || "",
        slug: state.product_category?.slug || "",
        image: state.product_category?.image || "",
        metier: state.product_category?.metier || "",
        parent_id: state.product_category?.parent_id || "",
        created_at: state.product_category?.created_at || "",
        updated_at: state.product_category?.updated_at || "",
      },
      product_marque: {
        id: state.product_marque?.id || "",
        name: state.product_marque?.name || "",
        slug: state.product_marque?.slug || "",
        image: state.product_marque?.image || "",
        created_at: state.product_marque?.created_at || "",
        updated_at: state.product_marque?.updated_at || "",
        description: state.product_marque?.description || "",
      },
      product_option: state.product_option.map((option) => ({
        id: option.id || "",
        product: option.product || "",
        option: option.option || "",
        value: option.value || "",
        created_at: option.created_at || "",
        updated_at: option.updated_at || "",
      })),
      images: state.images.map((image) => ({
        id: image.id || "",
        product_id: image.product_id || "",
        type: image.type || "",
        path: image.path || "",
        featured: image.featured || "",
        created_at: image.created_at || "",
        updated_at: image.updated_at || "",
      })),
      id: state.id || "",
    }
  
  // Function to handle SAVE confirmation
  const handleSaveConfirm = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", updatedProductData.id);
      updatedProductData.titre && formData.append("titre", updatedProductData.titre);
      updatedProductData.slug && formData.append("slug", updatedProductData.slug);
      updatedProductData.reference && formData.append("reference", updatedProductData.reference);
      updatedProductData.description &&  formData.append("description", updatedProductData.description);
      updatedProductData.specification &&  formData.append("specification", updatedProductData.specification);
      updatedProductData.conseil &&  formData.append("conseil", updatedProductData.conseil);
      updatedProductData.tva && formData.append("tva", updatedProductData.tva);
      updatedProductData.ht && formData.append("ht", updatedProductData.ht);
      updatedProductData.ttc && formData.append("ttc", updatedProductData.ttc);
      updatedProductData.status && formData.append("status", updatedProductData.status);
      updatedProductData.quantite && formData.append("quantite", updatedProductData.quantite);
      updatedProductData.initial_quantity && formData.append("initial_quantity", updatedProductData.initial_quantity);
      updatedProductData.marque && formData.append("marque", updatedProductData.marque);
      updatedProductData.categorie && formData.append("categorie", updatedProductData.categorie);
      updatedProductData.options && updatedProductData.options.forEach((opt, index) => {
        formData.append(`options[${index}][option]`, opt.option);
        formData.append(`options[${index}][value]`, opt.value);
      });
      fils.length > 0 &&
        fils.forEach((file) => {
          formData.append(`files[]`, file);
        });
        await updateProductData(formData);  //update product
      window.location.reload();  //reload page
      setShowConfirmationModal(false);
      setShowOkModal(true); // Show OkModal when save is successful
    } catch (error) {
      // Handle the error (display a message, log, etc.)
      console.error("Error saving product dataxx:", error);
    } finally {
      setLoading(false);
    }
  };
  // Function to handle SAVE Option confirmation
  const handleSaveOption = async () => {
    setLoading(true);
    try {
      // Extract key-value pairs from optionsProducts
      // Call the updateOptionData API with the extracted data
      await updateOptiontData(productId, optionsProducts);
      // Optionally, you can add a success message or perform additional actions
      setShowConfirmationModal(true);
      setShowOkModal(true); // Show OkModal when save is successful
    } catch (error) {
      // Handle the error (display a message, log, etc.)
      console.error("Error saving product data:", error);
    } finally {
      setLoading(false);
    }
  };
  //******************************************************************************************************************/
  // Function to handle modal exit
  const handleCloseConfirmationModal = () => {
    // If the user cancels, simply close the modal
    setShowConfirmationModal(false);
  };
  //Function to get the parent Categorie
  const getCatParent = async (subCategorieId) => {
    const catParent = await fetchCategorieParent(subCategorieId);
    setCategorieParente(catParent[catParent?.length-1]?.id);
    
  };
  //******************************************************************************************************************/
  useEffect(() => {
    if (productData && productData.categorie) {
      setCategorieSelected(productData.categorie); // Assigner la catégorie enfant sélectionnée
      getCatParent(productData.categorie); // Charger la catégorie parente
    }
    setState(updatedProductData || initialState);
    fetchData(productId);
    fetchCategories();
    fetchMarques();
   // handleChoice();  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]); // Empty dependency array means it will run once when the component mounts
  useEffect(() => {
    if (categorieParente) {
      // Charger les sous-catégories lorsque la catégorie parente est définie
      const fetchSubCategories = async () => {
        try {
          const response = await fechCategoriesPerParents(categorieParente);
          if (response?.data) {
            setSubCategorie(response.data); // Mettre à jour les sous-catégories
          } else {
            setSubCategorie([]);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des sous-catégories :", error);
          setSubCategorie([]); // Effacer les sous-catégories en cas d'erreur
        }
      };
      fetchSubCategories();
    }
  }, [categorieParente]);
  

  const calculateTTC = (ht, tva) => {
    if (!ht || !tva)
    return ""; // Handle cases where either value is not set
    const ttc = parseFloat(ht) + (parseFloat(ht) * (parseFloat(tva) / 100));
    return ttc.toFixed(2); // Format to 2 decimal places if needed
  };

  // Update your handleChange function to include this logic
const handleChange = (field, value) => {
  setState(prevState => {
    const newState = { ...prevState, [field]: value };

    // Calculate TTC when HT or TVA changes
    if (field === "ht" || field === "tva") {
      newState.ttc = calculateTTC(newState.ht, newState.tva);
    }

    return newState;
  });
};

  // If product data is not available yet, display a loading indicator

  if (!productData || loading) {
    return (
      <Container>
        <div className='productList d-flex justify-content-center align-items-center vh-100"'>
          {/* Display loader while data is being fetched */}
          <Spinner animation="border" role="status" className="mt-5">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }
  //******************************************************************************************************************/
  // Render the component
  return (
    <Container>
      <div className="productList">
        {/* Breadcrumb navigation */}
        <p className="h6">
          Accueil / Produits / {updatedProductData.product_marque.name}
        </p>

        {/* Header and filters */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            {/* Display dynamic title */}
            <h1 className="p-1">
              {updatedProductData.product_marque.name.substring(0, 20)}
            </h1>
          </Col>
        </Row>

        {/* Render the component */}
        {loading ? (
          // Display loader while data is being fetched
          <p className="lead">Chargement...</p>
        ) : (
          <div>
            <Row>
              <ImageUploder setFils={setFils} fils={fils} errors={errors} />
              <Row>
                {productData.images.map((image, index) => (
                  <Col
                    md="auto"
                    key={index}
                    style={{ display: "inline-block" }}
                  >
                    <Card style={{ width: "18rem", display: "inline-block" }}>
                      {image.type !== "image" ? (
                        // Render a PDF if the image type is '/document'
                        <embed
                           src={`${urlPdf}/${image.path}`}
                          type="application/pdf"
                          width="100%"
                          height="400px"
                        />
                      ) : (
                        // Render an image if the image type is not '/document'
                        <Card.Img
                          variant="top"
                            src={`${urlPdf}/${image.path}`}
                        />
                      )}
                    </Card>
                    <br />

                    <Button
                      variant="danger"
                      size="sm"
                      className="mt-2"
                      onClick={() => handleDeleteImage(image.id)}
                    >
                      Supprimer
                    </Button>
                  </Col>
                ))}
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <Form>
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Title className="pb-3">
                          Information générale
                        </Card.Title>
                        <Row>
                          <Col>
                            {/* Titre Field */}
                            <Form.Group controlId="formTitre">
                              <Form.Label>Titre</Form.Label>
                              <Form.Control
                                type="text"
                                value={updatedProductData?.titre}
                                placeholder="Entrer titre"
                                onChange={(e) =>
                                  handleChange("titre", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* Slug Field */}
                            <Form.Group controlId="formSlug">
                              <Form.Label>Slug</Form.Label>
                              <Form.Control
                                type="text"
                                value={updatedProductData?.slug}
                                placeholder="Entrer slug"
                                onChange={(e) =>
                                  handleChange("slug", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* Reference Field */}
                            <Form.Group controlId="formReference">
                              <Form.Label>Référence</Form.Label>
                              <Form.Control
                                type="text"
                                value={updatedProductData?.reference}
                                placeholder="Entrer reference"
                                onChange={(e) =>
                                  handleChange("reference", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group id="formStatus" className="pb-3">
                              <Form.Label>Status</Form.Label>
                              <Form.Control
                                as="select"
                                value={updatedProductData?.status}
                                onChange={(e) =>
                                  handleChange("status", e.target.value)
                                }
                                isInvalid={!!errors["status"]}
                              >
                                <option value="" disabled>
                                  Sélectionner le status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* Quantite Field */}
                            <Form.Group controlId="formQuantite">
                              <Form.Label>Quantité</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedProductData?.quantite}
                                placeholder="Entrer une quantité"
                                onChange={(e) =>
                                  handleChange("quantite", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="formQuantiteinitiale">
                              <Form.Label>Quantité max par panier</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedProductData?.initial_quantity}
                                placeholder="Entrer quantité max par panier"
                                name="initial_quantity"
                                onChange={(e) =>
                                  handleChange(
                                    "initial_quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Description Field */}
                        <Form.Group controlId="formDescription">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={updatedProductData?.description}
                            rows={3}
                            placeholder="Entrer description"
                            onChange={(e) =>
                              handleChange("description", e.target.value)
                            }
                          />
                        </Form.Group>

                        {/* Specification Field */}
                        <Form.Group controlId="formSpecification">
                          <Form.Label>Spécification</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={updatedProductData?.specification}
                            rows={10}
                            placeholder="Entrer specification"
                            onChange={(e) =>
                              handleChange("specification", e.target.value)
                            }
                          />
                        </Form.Group>

                        {/* Conseil Field */}
                        <Form.Group controlId="formConseil">
                          <Form.Label>Conseil</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={updatedProductData?.conseil}
                            rows={3}
                            placeholder="Entrer conseil"
                            onChange={(e) =>
                              handleChange("conseil", e.target.value)
                            }
                          />
                        </Form.Group>
                        <Row>
                          <Col>
                            {/* HT Field */}
                            <Form.Group controlId="formHT">
                              <Form.Label>HT</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedProductData?.ht}
                                placeholder="Entrer HT"
                                onChange={(e) =>
                                  handleChange("ht", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* TTC Field */}
                            <Form.Group controlId="formTTC">
                              <Form.Label>TTC</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedProductData?.ttc}
                                placeholder="Entrer TTC"
                                onChange={(e) =>
                                  handleChange("ttc", e.target.value)
                                }
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* TVA Field */}
                            <Form.Group controlId="formTVA">
                              <Form.Label>TVA</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedProductData?.tva}
                                placeholder="Enter TVA"
                                onChange={(e) =>
                                  handleChange("tva", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* Marque Field */}
                            <Form.Group controlId="formMarque">
                              <Form.Label>Marque</Form.Label>
                              <Form.Control
                              as="select"
                              className="form-select"
                                value={updatedProductData?.marque || ""}
                                onChange={(e) =>
                                  handleChange("marque", e.target.value)
                                }
                              >
                                <option value="">Sélectionner une marque</option>
                                {marquesOptions?.map((marque) => (
                                  <option key={marque.id} value={marque.id}>
                                    {marque.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="formCategorie">
                              <Form.Label>Catégorie Parente</Form.Label>
                              <Form.Control
                                as="select"
                                className="form-select"
                                value={categorieParente}
                                onChange={(e) => handleChoice(e.target.value)}
                              >
                                <option>
                                  Sélectionner une catégorie
                                </option>
                                {renderSelectOptions(categories)}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* Categorie Field */}
                            <Form.Group controlId="formsubCategorie">
                              <Form.Label>Catégorie</Form.Label>
                              <Form.Control
                                as="select"
                                className="form-select"
                                value={categorieSelected}
                                onChange={(e) => {handleChange("categorie", e.target.value);
                                  setCategorieSelected(e.target.value)}}
                              >
                                <option value="">
                                  Sélectionner une catégorie
                                </option>
                                {/* {renderSelectsubCategorie(subCategorie)} */}
                                {renderSelectOptions(subCategorie)}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mt-3 d-flex justify-content-end align-items-center"
                              controlId="formComment"
                            >
                              <Button
                                variant="primary"
                                onClick={handleSaveClick}
                              >
                                Enregistrer
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title className="pb-3">
                        Modifier Options du Produit
                      </Card.Title>
                      {/* Additional Card for Options */}
                      <div className="mt-12">
                        {optionsProducts?.map((option, index) => (
                          <div className="row pb-4" key={index}>
                            <Form.Group
                              controlId={`option-${index}`}
                              className="col-md-4 "
                            >
                              <b
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => supprimerLigne(index)}
                              >
                                x
                              </b>{" "}
                              <Form.Label>Option</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter option"
                                value={option.key}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    "key",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              controlId={`value-${index}`}
                              className="col-md-4"
                            >
                              <Form.Label>Valeur:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter value"
                                value={option.value}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                      {/* Save Button */}
                      <Button
                        variant="primary"
                        className="mt-2"
                        onClick={handleAddOption}
                      >
                        Add Option
                      </Button>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mt-3 d-flex justify-content-end align-items-center"
                            controlId="formComment"
                          >
                            <Button
                              variant="primary"
                              onClick={handleSaveOption}
                            >
                              Enregistrer
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Row>
          </div>
        )}
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={
          actionType === "save"
            ? handleSaveConfirm
            : actionType === "saveOption"
            ? handleSaveOption
            : handleDeleteConfirm
        }
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

// Export the component as the default export
export default EditScreen;
