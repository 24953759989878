import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClickBack from "../../../components/buttons/ClickBack";
import { useEffect, useState } from "react";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../../utils/ApiConfig.js";

function AddFicheFournisseur(props) {
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const {
    initialAddress = "",
    initialaltitudeFournisseur = "",
    initiallongitudeFournisseur = "",
    initialEmail = "",
    initialRaisonSociale = "",
    initialStatus = 1,
    initialTel = "",
    initialMontantHT = "",
    initialCommission = "",
    handleSubmit,
    apiErrors = {},
  } = props;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email invalide").required("Email est requis"),
    etat: Yup.number().required("Etat est requis"),
    TelFournisseur: Yup.number().required("Numéro de Téléphone est requis"),
    RaisonSocial: Yup.string().required("Raison Social est requis"),
    montantHT: Yup.number().required("Tarif de Livraison est requis"),
    commission: Yup.number().required("Commission est requise"),
    address: Yup.string().required("Adresse est requise"),
  });

  const handleAddressChange = async (setFieldValue, address) => {
    try {
      const results = await geocodeByAddress(address);
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        setFieldValue("address", address);
        setFieldValue("altitudeFournisseur", lat);
        setFieldValue("longitudeFournisseur", lng);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const searchOptions = {
    componentRestrictions: { country: ["fr","mc"] },
  types: ["geocode","establishment"],
  };
  useEffect(() => {
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true);
    }
  }, []);
  return (
    <Container>
      <div className="userFournisseurs">
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Ajouter Fournisseur</h1>
          </Col>
        </Row>
        <Formik
          initialValues={{
            email: initialEmail,
            etat: initialStatus,
            TelFournisseur: initialTel,
            montantHT: initialMontantHT,
            commission: initialCommission,
            address: initialAddress,
            altitudeFournisseur: initialaltitudeFournisseur,
            longitudeFournisseur: initiallongitudeFournisseur,
            RaisonSocial: initialRaisonSociale,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <FormikForm onSubmit={handleSubmit}>
              {/* Information personnelle */}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">
                    Informations personnelles :
                  </Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Adresse e-mail</Form.Label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                        {apiErrors.email && (
                          <div className="text-danger">{apiErrors.email}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formRaisonSociale"
                      >
                        <Form.Label>Raison Sociale</Form.Label>
                        <Field
                          type="text"
                          name="RaisonSocial"
                          placeholder="Raison Sociale"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="RaisonSocial"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formStatus">
                        <Form.Label>Etat</Form.Label>
                        <Form.Select
                          name="etat"
                          className="form-control"
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setFieldValue("etat", value);
                          }}
                          onBlur={handleBlur}
                          // value={values.etat === "1" ? 1 : 0}
                        >
                          <option value="" disabled>
                            Sélectionnez Etat
                          </option>
                          <option value="1">Active</option>
                          <option value="0">Inactif</option>
                        </Form.Select>
                        <ErrorMessage
                          name="etat"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="form_telfournisseur"
                      >
                        <Form.Label>N° de téléphone Fournisseur </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="TelFournisseur"
                          placeholder="N° de téléphone"
                          value={values.TelFournisseur}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.TelFournisseur && !!errors.TelFournisseur
                          }
                        />
                        <ErrorMessage
                          name="TelFournisseur"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {isGMapsLoaded && (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title className="pb-3">Adresse :</Card.Title>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formAddress">
                          <Form.Label>Adresse</Form.Label>
                          <PlacesAutocomplete
                            value={values.address}
                            searchOptions={searchOptions}
                            onChange={(address) =>
                              setFieldValue("address", address)
                            }
                            onSelect={(address) =>
                              handleAddressChange(setFieldValue, address)
                            }
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Adresse",
                                    className: "form-control",
                                  })}
                                />
                                <ErrorMessage
                                  name="address"
                                  component="div"
                                  className="text-danger"
                                />
                                <div
                                  className={`container-autocomplete container-adr ${
                                    suggestions.length > 0
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                >
                                  {loading && <div>Chargement...</div>}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41B6E6"
                                        : "#fff",
                                      cursor: "pointer",
                                    };
                                    return (
                                      <div
                                        className="mapItem"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {/* Montants */}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">Montants :</Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formMontantHT">
                        <Form.Label>Tarif de Livraison</Form.Label>
                        <Field
                          type="number"
                          name="montantHT"
                          placeholder="Tarif de Livraison"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="montantHT"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formCommission">
                        <Form.Label>Commission %</Form.Label>
                        <Field
                          type="number"
                          name="commission"
                          placeholder="Commission %"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="commission"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div md={6} className="text-end">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Ajouter Fournisseur
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default AddFicheFournisseur;
